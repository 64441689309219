import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOneContactSection";

class ContactOne extends Component {
    state = {
        data: {
          "heading": "Sign Up",
          "headingText_1": "We're in beta, please your invitation code below:",
          "headingText_2": "",
          "btnText": "Send Message",
          "contactData": [
            {
              "id": 1,
              "listClass": "contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3",
              "listClass_1": "contact-info color-1 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
              "iconClass": "fas fa-mobile-alt fa-3x",
              "linkClass": "d-block my-2",
              "title": "+89 (0) 2354 5470091",
              "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
            },
            {
              "id": 2,
              "listClass": "contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3",
              "listClass_1": "contact-info color-3 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
              "iconClass": "fas fa-envelope-open-text fa-3x",
              "linkClass": "d-block my-2",
              "title": "mail@company.com",
              "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
            }
          ]
        },
        contactData: [
          {
            "id": 1,
            "listClass": "contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3",
            "listClass_1": "contact-info color-1 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
            "iconClass": "fas fa-mobile-alt fa-3x",
            "linkClass": "d-block my-2",
            "title": "+89 (0) 2354 5470091",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
          },
          {
            "id": 2,
            "listClass": "contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3",
            "listClass_1": "contact-info color-3 bg-hover active hover-bottom dark-shadow text-center p-5 m-3",
            "iconClass": "fas fa-envelope-open-text fa-3x",
            "linkClass": "d-block my-2",
            "title": "mail@company.com",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit."
          }
        ]
    }

    render() {
        return (
            <section id="contact" className="contact-area ptb_50">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12">
                            {/* Section Heading */}
                          <div className="section-heading text-center mb-3">
                            <h2>Enter Invitation Code</h2>
                            <p className="d-none d-sm-block mt-4">Alyria is currently invite only, please enter your invitation code below:</p>
                            <br/>
                            <div className="form-group row  justify-content-center">
                              <div className="col-xs-2 align-content-center">
                              <input type="text" className="form-control col-xs-2 align-content-center" name="invitationCode"
                                     placeholder="Invitation Code" required="required" maxLength="10" max={10} />
                              <btn className="btn btn-bordered active btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-cocktail" /></span>Join</btn>
                              </div>
                            </div>
                          </div>
                          {/*/!* Contact Us *!/*/}
                          {/*<div className="contact-us">*/}
                          {/*    <ul>*/}
                            {/*        /!* Contact Info *!/*/}
                            {/*        {this.state.contactData.map((item, idx) => {*/}
                            {/*            return(*/}
                            {/*                <li key={`co_${idx}`} className={item.listClass}>*/}
                            {/*                    <span><i className={item.iconClass} /></span>*/}
                            {/*                    <a className={item.linkClass} href="/#">*/}
                            {/*                        <h3>{item.title}</h3>*/}
                            {/*                    </a>*/}
                            {/*                    <p>{item.text}</p>*/}
                            {/*                </li>*/}
                            {/*            );*/}
                            {/*        })}*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>
                        {/*<div className="col-12 col-lg-6 pt-4 pt-lg-0">*/}
                        {/*    /!* Contact Box *!/*/}
                        {/*    <div className="contact-box text-center">*/}
                        {/*        /!* Contact Form *!/*/}
                        {/*        <form id="contact-form" method="POST" action="/php/mail.php">*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col-12">*/}
                        {/*                    <div className="form-group">*/}
                        {/*                        <input type="text" className="form-control" name="name" placeholder="Name" required="required" />*/}
                        {/*                    </div>*/}
                        {/*                    <div className="form-group">*/}
                        {/*                        <input type="text" className="form-control" name="name" placeholder="Company Name" required="required" />*/}
                        {/*                    </div>*/}
                        {/*                    <div className="form-group">*/}
                        {/*                        <input type="email" className="form-control" name="email" placeholder="Email" required="required" />*/}
                        {/*                    </div>*/}
                        {/*                    <div className="form-group">*/}
                        {/*                        <input type="text" className="form-control" name="subject" placeholder="Phone" required="required" />*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-12">*/}
                        {/*                    <div className="form-group">*/}
                        {/*                        <textarea className="form-control" name="message" placeholder="Message" required="required" defaultValue={""} />*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-12">*/}
                        {/*                    <button type="submit" className="btn btn-bordered active btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>{this.state.data.btnText}</button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </form>*/}
                        {/*        <p className="form-message" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactOne;
