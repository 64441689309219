import React, { Component } from 'react';

const initData = {
    heading: "Need an invite code?",
    headingText_1: "",
    headingText_2: "",
    btnText: "Contact Us"
}

class Cta extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="section cta-area color-1 ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            {/* Section Heading */}
                          <div className="section-heading text-center m-0">
                            <h2 className="text-white">{this.state.data.heading}</h2>
                            <p className="text-white d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                            <p className="text-white d-block d-sm-none mt-4">{this.state.data.headingText_2}</p>
                            {/*<a href="/contact" className="btn btn-bordered-white mt-4">{this.state.data.btnText}</a>*/}
                            <iframe height="380" title="Request Invitation Code Form" allowTransparency="true" frameBorder="0"
                                    scrolling="no" style={{width: "100%", border: "none"}}
                                    sandbox="allow-popups-to-escape-sandbox allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin"
                                    src="https://krscareers.wufoo.com/embed/z1sg5o871soy8va/"><a
                              href="https://krscareers.wufoo.com/forms/z1sg5o871soy8va/"></a>
                            </iframe>
                          </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cta;
