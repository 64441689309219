import React, { Component } from 'react';

import ScrollupSection from '../../Scrollup/Scrollup';
import Header from '../../Header/HeaderThree';
import BreadcrumbSection from '../../Breadcrumb/BreadcrumbThree';
import ServiceSection from '../../Services/ServiceSeven';
import ReviewSection from '../../Reviews/ReviewOne';
import ContactSection from '../../Contact/ContactOne';
import CtaSection from '../../Cta/Cta';
import FooterSection from '../../Footer/FooterOne';
import ModalSearchSection from '../../Modal/ModalSearch';
import ModalMenuSection from '../../Modal/ModalMenu';

class SignUp extends Component {
    render() {
        return (
            <div>
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <BreadcrumbSection heading={"SignUp"} home={"Home"} page={"Pages"} title={"Sign Up"} />

                    <ContactSection />
                    <CtaSection />
                    {/*<FooterSection />*/}
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default SignUp;
