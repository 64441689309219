import React, { Component } from 'react';

class HeaderThree extends Component {
  render() {
    return (
      <header id="header">
        {/* Navbar */}
        <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
          <div className="container header">
            {/* Navbar Brand*/}
            <a className="navbar-brand" href="/">
              <img className="navbar-brand-regular" src={this.props.imageData} alt="brand-logo" />
              <img className="navbar-brand-sticky" src="/img/logo.png"
                   alt="sticky brand-logo" />
              <h3 className="navbar-brand-regular">lyria</h3><h3 className="navbar-brand-sticky">lyria</h3>

            </a>

            <div className="ml-auto" />
            {/*/!* Navbar *!/*/}
            <ul className="navbar-nav items">
              <li className="nav-item">
                <a className="nav-link scroll" href="/">Home</a>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link scroll" href="#about">About</a>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link scroll" href="#services">Pricing</a>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link scroll" href="#portfolio">Portfolio</a>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link scroll" href="#pricing">Pricing</a>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <a className="nav-link scroll" href="#contact">Log in</a>*/}
              {/*</li>*/}
            </ul>
            {/*/!* Navbar Icons *!/*/}
            <ul className="navbar-nav icons">
              {/*<li className="nav-item">*/}
              {/*  <a href="/#" className="nav-link" data-toggle="modal" data-target="#search">*/}
              {/*    <i className="fas fa-search" />*/}
              {/*  </a>*/}
              {/*</li>*/}
            {/*  <li className="nav-item social">*/}
            {/*    <a href="/#" className="nav-link"><i className="fab fa-facebook-f" /></a>*/}
            {/*  </li>*/}
            {/*  <li className="nav-item social">*/}
            {/*    <a href="/#" className="nav-link"><i className="fab fa-twitter" /></a>*/}
            {/*  </li>*/}
            </ul>
            {/*/!* Navbar Toggler *!/*/}
            {/*<ul className="navbar-nav toggle">*/}
            {/*  <li className="nav-item">*/}
            {/*    <a href="/#" className="nav-link" data-toggle="modal" data-target="#menu">*/}
            {/*      <i className="fas fa-bars toggle-icon m-0" />*/}
            {/*    </a>*/}
            {/*  </li>*/}
            {/*</ul>*/}
            {/* Navbar Action Button */}
            <ul className="navbar-nav action">
              <li className="nav-item ml-3">
                <a href="/#" className="btn ml-lg-auto btn-bordered-white"><i
                  className="fas fa-solid fa-user  mr-md-2" />Log in</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default HeaderThree;
