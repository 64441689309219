import React, { Component } from 'react';

const initData = {
    heading: "Eternal Friendship",
    heading_span: "Wherever You Are, Your Closest Friend Is Just a Chat Away.",
    heading_span1: "Always there; always ready.",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.",
    btnText_1: "Create your AI Companion",
    btnText_2: "a"
}

class HeroFour extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-10">
                          <div className="welcome-intro text-center mt-0 py-5">
                            <img src="/img/21apr24b.png" alt="img" className="img-fluid" width="80%" />
                            <h1 className="text-white">{this.state.data.heading} <br />
                            </h1>
                            <h4>{this.state.data.heading_span}</h4>
                            {/*<p className="text-white my-4">{this.state.data.content}</p>*/}

                            {/* Buttons */}
                            <div className="button-group">
                              {/* a href to sign up route in react */}
                              <a href="/signup" className="btn btn-bordered-white">{this.state.data.btnText_1}</a>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroFour;
